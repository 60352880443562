@import url(https://fonts.googleapis.com/css?family=Montserrat);

body {
  margin: 0;
  padding: 0;  
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat' !important;
}
.container-fluid{
  padding: 0 !important;
}



.wrapper {
    background-image: url(https://crm.elektrokrajina.com/crm/static/media/elektrokrajina_bck.5da7245e.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    min-height: 100vh;
}

.container {
    min-height: 100vh;
}

.ponuda-img {
    margin: auto;    
    font-size: 50px !important;
}

.labelForgotPassword {
    color: blue;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
    text-align: end;
}

.labelForgotPassword:hover{
    color:#052f72;
}

.ponuda-p {
    font-size: 12px;
    margin-top: 10px;
}

.form-container {
    background-color: white;
    height: 100%;
    border-radius: 25px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
}

.submit-button {
    background-color: #153a78!important;
    color: white!important;
    font-weight: bold;    
    border-radius: 100px !important;
}

.submit-button:hover{
    background-color: #1a73a7 !important;
}

h3>a {
    font-style: italic;
    text-decoration: underline;
    color: #2764c6;
    font-size: 20px;
    margin-top: 7px;
}

h3>a:hover {
    color: #052f72;
}

@media screen and (max-width: 600px) {
    .cover {
        padding: 0;
    }
    .logoImg {
        height: 50px;
        margin: 15px 10px 15px 10px;
    }
}

.horizontalLine {
    margin: 0;
    background-color: lightgray;
    height: 1px;
}

.nopadding{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px;
    margin-left: 0px !important;
}

.content{
    display: flex;
    flex-direction: column;
}

.content_order1{
    position: absolute;
    height: auto;
}

.content_order2{
    height: 100%;
    overflow-y: scroll;
}

.fieldsLine{    
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #153a78 !important;
    border-radius: 0% !important;
}

.comboBorder{
    border: 1px solid #153a78 !important;
}

.logo {
    padding: 10px 15px;
    background-color: white;
}

.buttonLogin{    
    min-width: 200px;
    max-width: 300px;
}

.reset-container{
               /* background-color: rgba(211, 211, 211, 0.5);*/
                padding: 10px;
                width: 100%;
                height: 100%;
                margin: auto;
           
               
           }
.cover{
               height: 100%;
               min-height: 100vh;
               position: relative;
           }

.text1 {
       font-size:24px;
       color:white;
       padding: 5px;
}

.text {
    font-size:14px;
    color:white;
    padding: 3px;
}
.txt {
    padding: 7px;
}

.home-title{
    color:  #2674b3;
    font-size: 1.25rem;
}
.home-td-left{
    color:  #2674b3;
    font-weight: 600;
    font-size: 1rem;
}
.no-pad-mar{
    padding: 0px !important;
    margin: 0px !important;
}
.master{
   display: inline;
}
.home-content{
    margin: 20px;
    width: 95% !important;
}
.home-card-box{
    padding: 5px;
    height: 100%;
    border: none !important;
    box-shadow: 6px 6px 5px 0px rgba(38,116,179,0.46) !important;
}
.home-info-box{
    background-color: red;
    color: white;
    height: 90px;
    border-radius: 20px;
    margin: 3px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    padding: 5px;
}
.home-info-icon-content{
    position: relative;
    transform: translateY(30%);
    margin: auto;
}

.home-info-box-icon{
    font-size: 40px ;
    margin-left: 20px;
}

.home-font {
    font-size: 2.2rem!important;
    font-weight: bold;
}
.home-word-wrap{
    word-wrap: break-word;
}

#home-box-positiv{
    background-image: linear-gradient(175deg, rgba(21,58,120,1)0%, rgba(21,58,120,1) 40%, rgba(39,93,185,1) 100%);
}
#home-box-negativ{
    background-image: linear-gradient(175deg, rgba(255,76,76,1)0%, rgba(255,76,76,1) 40%, rgba(255,126,51,1) 100%);
}
#home-box2{
    background-image: linear-gradient(175deg, rgba(153,153,153,1)0%, rgba(153,153,153,1) 40%, rgba(204,204,204,1) 100%);
}
#home-box3{
    background-image: linear-gradient(175deg, rgba(153,204,51,1)0%, rgba(153,204,51,1) 40%, rgba(192,196,70,1) 100%);
}
#home-box4{
   background-image: linear-gradient(175deg, rgba(60,161,220,1)0%, rgba(60,161,220,1) 40%, rgba(60,196,220,1) 100%);
}
.home-info-card-icon{
    color: #153a78;
    font-size: 24px !important;
 }
 .home-info-card-label{
     color: #153a78;
     font-size: 20px;
     font-weight: bold; 
 }
 .home-info-card-h5{
     color: black;
 }
 .home-info-card-h6{
     color: gray;
 }

@media (min-width: 200px) {  
  .home-h4 {font-size: 1.1rem !important}
  .home-info-box-icon{font-size: 2rem !important;}
}

@media (min-width: 768px) {  
  .home-h4 {font-size:1.1rem !important;}
  .home-info-box-icon{font-size: 2rem !important;}
}
@media (max-width: 900px) {  
    .home-content{
         padding-right: 5px !important;  
         padding-left: 5px !important;}
  }

@media (min-width: 992px) { 
  .home-h4 {font-size: 1.0rem!important;} 
  .home-info-box-icon{font-size: 2rem !important;}
}


@media (min-width: 1200px) {  
  .home-h4 {font-size:1.2rem !important;}    
  .home-info-box-icon{font-size: 2rem !important;}
}
.home-info-box-label{
    margin: 20px 0 0 15px;
    font-size: 12px !important;
}
.home-info-box-value{
    margin-left: 15px;
    font-size: 30px ;
    font-family: Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

@media (min-width: 500px){
    .home-info-box-label{
        font-size: 10px !important;
    }
    .home-info-box-value{
        font-size: 20px !important;
    }
}
@media (min-width: 768px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
        font-size: 22px !important;
    }
}
@media (min-width: 830px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
        font-size: 24px !important;
    }
}
@media (min-width: 1200px){
    .home-info-box-label{
        font-size: 11px !important;
    }
    .home-info-box-value{
        font-size: 22px !important;
    }
}
@media (min-width: 1320px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
            font-size: 23px !important;
        }
    }
@media (min-width: 1558px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
            font-size: 30px !important;
        }
    }


.custom-button{
    float: right;
}
@media screen and (max-width: 967px) {
    .custom-button{
        float: left;
    }
}
.red{
    color: red;
    font-weight: bold;
}
.green{
    color: green;
    font-weight: bold;
}
.inline{
    display: inline;
    padding-left: 7px;
}

.measuring-point-box {
    width: 100%;
    box-shadow: 5px 0px 10px 3px lightgrey;
    margin-bottom: 8px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.header-icon{
    margin-right: 10px;
}
.backslash{
    margin-left: 10px;
    color: gray;
    font-weight: bold;
}
.data-picker{
    background-color: #f5faff;
}
.react-datepicker-wrapper{
    width: 100%;
}
.data-picker{
    width: 100%;
}
.table-box{
    border-bottom: 1px solid #153a78;
    border-top: 1px solid #153a78;
}
.mp-bold-value{
    font-weight: bold;
}
.text-font-size{
    font-size: 18px;
}
.mp-table-border-top{
    border-top: 1px solid #153a78;
    margin-top: 3px;
}
@media screen and (min-width: 800px) {
    .text-font-size {
        font-size: 18px !important;
    }
}
@media screen and (max-width: 800px) {
    .text-font-size {
        font-size: 2vw;
    }
}
@media screen and (max-width: 650px) {
    .text-font-size {
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 500px) {
    .text-font-size {
        font-size: 3vw;
    }
}
@media screen and (max-width: 400px) {
    .text-font-size {
        font-size: 4vw;
    }
}

.custom-button{
  border: none;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-left: 5px;
  border-radius: 10px;
  text-transform: uppercase;
}
.custom-button-normal{
    background-color: #153a78; 
    color: white;
}
.custom-button-dark{
    background-color: transparent;
    color:  #153a78; 
}
.custom-button:hover{
    opacity: 0.8;
    transition: opacity 0.3s;
}
.custom-button-icon{
    padding-right: 8px;
    font-size: 13px !important;
}
.custom-button-disabled{
    opacity: .65 !important;
}

@media screen and (max-width: 600px) {
    .custom-button{
        padding-top: 3px;
        font-size: 12px;
    }
}
.bill-activ-title{
    color: #153a78;
}
.bill-activ-content{
    text-align: justify;
}
.bill-page-title{
    display: block;
    font-weight: bold !important;
    color: #153a78  !important;
}

.bill-spiner {
    margin: auto;
    margin-top: calc(100vh - 65vh);
    width: 100px !important;
    height: 100px !important;
}

.bill-table {
    margin-top: 5px;
    border-collapse: collapse;
    color: #153a78 !important;
    background-color: white;
}

.bill-thead {
    position: relative !important;
    background-color: white;
    border-bottom: 2px solid #153a78;
    text-transform: uppercase;
}

.bill-td, .bill-th {
    text-align: center !important;
    color: #222222;
}
.bill-th{
    font-size: 18px;
    font-weight: bold;
    color: #153a78;
    
}

.bills-value {
    font-weight: bold;
    color: #222222;
    text-align: right !important;
}
.bill-text{
    text-align: left !important;
}
.bill-box {
    width: 100%;
    box-shadow: 5px 0px 10px 3px lightgrey;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    padding: 10px;
}

.bill-td-box-left {
    color: #153a78;
    font-weight: bold;
    width: 25%;
}

.bill-td-box-right {
    color: black;
    float: right;
}
.bill-box-view{
    width: 100%;
}
.bill-box-value{
    font-weight: bold;
}
.bill-table-view {
    width: 100%;
    display: block;
    padding: 10px 0px;
    box-shadow: 5px 0px 10px 3px lightgrey;
    border-radius: 20px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.bill-box-view {
    margin-top: 15px;
    display: none;
}

@media screen and (max-width: 600px) {
    .bill-td-box-right {
        font-size: 2.5vw;
    }
    .bill-table-view {
        display: none;
    }
    .bill-box-view {
        display: block;
    }
    .bill-td-box-left {
        font-size: 2.5vw;
    }
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white !important;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5faff;
}
.payment-spiner {
    margin: auto;
    margin-top: calc(100vh - 65vh);
    width: 100px !important;
    height: 100px !important;
}
.payment-page-title{
    display: block;
    font-weight: bold !important;
    color: #153a78  !important;
}
.payment-table {
    margin-top: 5px;
    border-collapse: collapse;
    color: #153a78 !important;
    background-color: white;
    
    
}

.payment-thead {
    position: relative !important;
    background-color: white;
    border-bottom: 2px solid #153a78;
    color: red !important;
    text-transform: uppercase;
}

.payment-td, .payment-th {
    text-align: center !important;
    color: #222222;
}
.payment-th{
    font-size: 18px;
    font-weight: bold;
    color: #153a78;
    
}

.payments-value {
    font-weight: bold;
    color: #222222;
    text-align: right !important;
}
.payment-text{
    text-align: left !important;
}

.payment-box {
    width: 100%;
    box-shadow: 5px 0px 10px 3px lightgrey;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.payment-td-box-left {
    color: #153a78;
    font-weight: bold;
    width: 25%;
}

.payment-td-box-right {
    color: black;
    float: right;
}

.payment-table-view {
    width: 100%;
    padding: 10px 0px;
    box-shadow: 5px 0px 10px 3px lightgrey;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.payment-box-view {
    margin-top: 15px;
    display: none;
}

.payment-box-value{
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .payment-td-box-right {
        font-size: 2.5vw;
    }
    .payment-table-view {
        display: none !important;
    }
    .payment-box-view {
        display: block;
        width: 100%;
    }
    .payment-td-box-left {
        font-size: 2.5vw;
    }
}
@media screen and (min-width: 800px) {
    .text-font-size {
        font-size: 2vw;
    }
}
@media screen and (max-width: 650px) {
    .text-font-size {
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 500px) {
    .text-font-size {
        font-size: 3vw;
    }
}
@media screen and (max-width: 400px) {
    .text-font-size {
        font-size: 4vw;
    }
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white !important;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5faff;
}
.card-header-colors{
    color:white!important;
    background-image: linear-gradient(to left, #153a78, #1b5292, #236cab, #2e86c4, #3ca1dc) !important;
}

.notDisplay{
    display: none;
}

.titlePrijavaStanja, .text{
    font-weight: bold !important;
    color: #153a78 !important;
}

.text{
    font-size: 15px;
}

.whole-box{
    border: 0px !important;
    height: 100%;    
}

.header-box{
    background-color: white !important;
    border: none !important;
    font-size: 18px !important;
    border-bottom: 2px solid #153a78 !important;
    border-radius: 10px 10px 0px 0px !important;
    box-shadow: 5px 5px 8px  lightgray, -5px 0 5px -5px lightgray, 0 -5px 15px -5px lightgray;
}

.body-box{
    background-color: #f5faff;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0px 0px 10px 10px !important;
    box-shadow: 5px 5px 8px lightgray, -5px 0 5px -5px lightgray;
}

.footer-box{
    background-color: white !important;
    border: none !important;
}

.card-box{
    border: none !important;
    overflow: hidden;
}

.fieldBox{
    background-color: #f5faff !important;
    border: none !important;
    font-weight: bold !important;
}

.btnPrijaviStanje{
    background-color: #153a78 !important; 
    color: white;
    border: none;
    padding: 4px 8px !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px !important;
    border-radius: 10px !important;
    text-transform: uppercase;
}

.btnPrijaviStanje:hover{
    opacity: 0.8;
    transition: opacity 0.3s;
}



.ps-table {
    margin-top: 5px;
    border-collapse: collapse;
    color: #153a78 !important;
    background-color: white;
}

.ps-table-body{
    background-color: #f5faff;
}

.ps-table-view {
    width: 100%;
    display: block;
    padding: 10px 0px ;
    box-shadow: 5px 0px 10px 3px lightgrey;
    /* border-radius: 20px; */
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.ps-thead {
    position: relative !important;
    border-bottom: 2px solid #153a78;
    text-transform: uppercase;
}

.notDisplay{
    display:none;
}

.poslOcitanje{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: .25rem;
    background-color: white;
}

.poslOcitanjeField{
    width: 20% !important;    
    background-color: white !important;
    border: none !important;
    font-weight: bold !important;
}

.utrosakField{
    width: 40% !important;
}

.kalkPotrosnje{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: .25rem;
    background-color: white;
}

.kalkPotrosnjeFont{
    font-size: 17px;
}

.poljaUtroska{
    background-color: #d4d7da !important;
    max-width: 200px;
}

.poljaUtroskaModal{
    background-color: #ffffff !important;
    max-width: 200px;
    margin-right: 50px !important;
    border: 0px !important;
}

.labelModal{
    margin-left: 20px !important;
}

.modalTableLeft{
    color: gray;
    width: 100%;
    padding-left: 20px !important;
}

.modalTableRight{
    color:gray;
    font-weight: bold;
    padding-right: 20px !important;
    text-align: right;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white !important;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5faff;
}

.titleKalkulatorPotrosnje, .text{
    font-weight: bold !important;
    color: #153a78 !important;
}

.text{
    font-size: 15px;
}

.whole-box{
    border: 0px !important;
    height: 100%;
}

.header-box{
    background-color: white !important;
    border: none !important;
    font-size: 18px !important;
    border-bottom: 2px solid #153a78 !important;
    border-radius: 10px 10px 0px 0px !important;
    box-shadow: 5px 5px 8px  lightgray, -5px 0 5px -5px lightgray, 0 -5px 15px -5px lightgray;
}

.body-box{
    background-color: #f5faff;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 5px 10px 8px lightgray, -5px 0 5px -5px lightgray;
}

.modalBody{
    padding: 0px !important;
}

.settings-tab-content{
    height: auto !important;
}
.settings-tab-container{
    width: 100% !important ;
}
.settings-profil-picture-content{
    width: 250px;
    padding: 5px;
    margin-top: 2px;
    height: 250px !important;
    height: 100%;
    margin: auto;
}
.settings-border-right{
    border-right: 1px solid gray;
}

.settings-nav-link {
    padding: 6px !important;
    color: gray !important;
}

/* The switch - the box around the slider */
.settings-switch {
  position: relative;
  float: right;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.settings-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.settings-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.settings-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .settings-slider {
  background-color: #2196F3;
}

input:focus + .settings-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .settings-slider:before {
  transform: translateX(13px);
}

/* Rounded sliders */
.settings-slider.settings-round {
  border-radius: 17px;
}

.settings-slider.settings-round:before {
  border-radius: 50%;
}

.settings-input-label{
    color: gray;
    font-size: 15px;
}
.settings-title-label{
  color: #153a78;
  font-weight: bold;
  font-size: 18px;
}
.image-container {
  position: relative;
  padding: 0 !important;
 
}

.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 12px; 
}
.btn-upload{
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: #555 !important;
  color: white !important;
  opacity: 0.8;
  font-size: 20px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 12px; 
}
.icon-upload{
  font-size: 20px;
}



.disabled{
  pointer-events: none;
  opacity: 0.7;
}

.custom-button-modal{
  float: right;
}
.modal-title{
  font-size: 18px !important;
  color: #153a78;
  font-weight: bold !important;
}
.settings-input-field{
  height: 25px !important;
}
.settings-modal-input-label{
   font-size: 14px !important;
}
.settings-modal-button{
  text-align: center;
}
.modal-backdrop{
  background-color: #153a78 !important;
  
}
.note-label{
  color: red;
  text-align: justify;
  margin: 3px;
 
}
.settings-custom-button{
  margin-left: 5px;
}
.settings-input-combobox{
  float: right;
  color: #153a78 !important;
  font-weight: bold !important;
  text-transform: uppercase;
  width: 150px !important;
}

@media screen and (max-width: 500px) {
  .settings-profil-picture{
      width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .settings-profil-picture{
      width: 350px;
  }
  .settings-input-combobox{
    float: left;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  .settings-button{
    margin-top: 25px;
    position: absolute;
  }
  
}
.title{
    font-weight: bold !important;
    color: #153a78 !important;
}
.notification-container{
    text-align: right;
}
.notification-item{
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
   -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}
.item-header{
    font-size:14px;  
    color:  #153a78; 
}
.notification-header{
    border-bottom:  2px solid #153a78 !important;
}
.item-content{
    text-align: justify;
    font-size:14px;
    background-color: #f5faff !important;
    width: 100%;    
    padding: 15px;
}

.item-flag {
    background-color: #f5faff !important;
    width: 100%;  
    height: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
   -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
    
}
.item-content-dialog{
    text-align: justify;
    font-size:14px;
    background-color: #f5faff !important;
    width: 100%;
    height: 800px;   
    padding: 15px; 
}
.item-date{  
  color: darkgray;  
  padding-left: 15px;
  font-size: 10px;
}
.item-date-card{  
    color: darkgray;  
    font-size: 10px;
    width: 100px;
  }
.first-row{
    padding-bottom: 5px;
}
.icon2{
    margin-top: 7px;
    margin-right: 15px;
    margin-bottom: 5px;
    float: right;  
    color:  #153a78;          
}
.pag-color{  
   color: #153a78 !important;
   font-weight: bold;
}
.lnc-icon{
    font-size: 20px !important;
    color: #153a78;
}
.div-button{
    float:right;
}
.one-notification{
    height: 90%;  
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
   -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
 }
.item-icon {
    color: darkgray;  
    margin-top:50%;
}
.header-row{
    margin-left: 40%;  
    margin-top: 10px;
}
.date-row{
    left: 60%;
    color: darkgray;  
    padding-top:3%;
}
.last-row{
    bottom:10%;
    position: fixed;
    width: 100%;
}
.page-numbers{
    margin-left: 20%;
    margin-bottom: 10%;
    font-size: 14px;
    position: fixed   
}
.paging-row{
    bottom:10%;
    position: fixed;
}
@media screen and (max-width: 600px) {
    .page-numbers{
        left: -50px;
        margin-bottom: 10%;
        position: fixed;
        font-size: 8px;
        color: #153a78; 
    }
    .notification-item{
        width: 280px;
        height: auto;
        margin-left: 0px;
        margin-right: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
    }   
    .item-content-dialog{
        text-align: justify;
        font-size:14px;
        background-color: #f5faff !important;
        width: 100%;
        height: auto;   
        padding: 15px; 
    }
}

.main-no-padding {
    padding: 0 !important;
}

.main-no-margin {
    margin: 0 !important;
}

.main-side-and-content {
    position: relative;
    height: calc(100vh - 56px);
    width: 100%;
}

.main-profil-pic {
    width: 60%;
    padding-top: 10px;
}

.main-side-link-activ:hover {
    color: white;
    text-decoration: none;
}


/*End Side Menu Style*/


/* Main Content Style*/

.main-main-content {
    background-color: white !important;
    z-index: 50;
    padding: 10px;
    margin-left: 60px !important;
    transition: margin-left 0.3s ease-out;
    height: 100%;
}

.logo-picture {
    padding: 10px 25px;
    background-color: white;
}

.logo-picture-mini {
    padding: 10px 3px 0 5px;
    background-color: white;
}

.profil-picture {
    border-radius: 10px;
}

.side-menu {
    background-color: #153a78!important;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 260px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    z-index: 100;
}

.side-menu.main-open {
    transform: translateX(0);
}

.side-content {
    overflow-y: auto;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.side-menu-item {
    padding: 3px 5px;
    color: #153a78;
    cursor:  pointer !important;
}

.side-menu-item:hover .side-menu-item-label {
    color: rgb(166, 236, 253) !important;
}

.side-menu-item:hover  {
    color: #3ca1dc!important;
    text-decoration: none;
}
.side-menu-item-icon{
    position: relative;
    top: 3px;
}

.side-menu-item-inverse {
    padding: 3px 5px;
    color: white;
    position: relative;
}

.side-menu-item-inverse:hover .side-menu-item-label {
    color: rgb(166, 236, 253) !important;
}



.side-menu-item-activ {
    color: #3ca1dc!important;
    padding: 3px 5px;
}

.main-profil-pic {
    width: 60%;
    padding-top: 10px;
}

.side-menu-item-icon {
    font-size: 20px !important;
    margin-top: 5px;
}

.side-menu-item-label {
    font-size: 16px;
    margin-left: 8px;
    font-weight: bold;
    cursor: pointer;
}

.side-bottom {
    background-color: white;
    height: 100%;
}

#user-info {
    text-align: center;
}

#first-half {
    background-color: white;
    border-bottom-left-radius: 25px;
}

#second-half {
    height: auto;
    background-color: white;
    color: white;
}

#second-half-child {
    border-radius: 0 20px 20px 0;
    background: rgb(21,58,120);
    background: linear-gradient(165deg, rgba(21,58,120,1) 0%, rgba(60,161,220,1) 80%, rgba(60,161,220,1) 100%);
}

#image-container {
    padding: 20px;
    margin: auto;
    max-width: 200px;
    max-height: 200px;
}

.img-profil {
    border-radius: 20px;
}

#div-1 {
    height: 25px;
    background-color: white;
}

#div-2 {
    background-color: #3ca1dc;
}

#div-3 {
    height: 25px;
    background-color: white;
    border-top-left-radius: 25px;
}

.hr {
  
    background-color: #2c76b2;
    width: 92%;
    margin: auto;
}
.side-menu-1 {
    background-color: #153a78;
    ;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    max-width: 60px;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
    z-index: 100;
    overflow-y: auto;
}

.side-menu-1.main-open-1 {
    transform: translateX(-100%);
}

@media(min-width: 769px) {
    .side-menu {
        transform: translateX(0);
    }
    .side-menu-1 {
        transform: translateX(-100%);
    }
    .main-main-content {
        background-color: lightgray;
        margin-left: 270px !important;
        padding: 30px;
    }
   
}
@media(max-width: 769px) {
    .side-menu-item{
        padding: 3px 10px;
    }
    .side-menu-item-inverse {
        padding: 3px 10px !important;
    }

}
/* Custom ScrollBar */

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  /* End Custom ScrollBar */

  .notification-badge{
    padding: 3px !important;
    position: absolute;
    top: -7px;
    z-index: 5;
    left: 16px;
}
 .notification-badge-child{
    position: absolute;
    bottom: 122px;
    left: 32px;
    width: 15px;
    height: 15px;  
 }
 
 
@charset "UTF-8";

/* LNC SVG font by LANACO | V1.2 | release date: 08.08.2019 */

@font-face {
  font-family: "lnclines";
  src:url(https://crm.elektrokrajina.com/crm/static/media/lnclines.06d94528.eot);
  src:url(https://crm.elektrokrajina.com/crm/static/media/lnclines.06d94528.eot?#iefix) format("embedded-opentype"),
    url(https://crm.elektrokrajina.com/crm/static/media/lnclines.1f15aa1c.woff) format("woff"),
    url(https://crm.elektrokrajina.com/crm/static/media/lnclines.369dfdf3.ttf) format("truetype"),
    url(https://crm.elektrokrajina.com/crm/static/media/lnclines.154aedf8.svg#lncines) format("svg");
  font-weight: normal;
  font-style: normal;

}

.lnc {
  font-family: "lnclines";
  font-size: 0.7em;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lnc-anchor:before {
  content: "a";
}
.lnc-armchair:before {
  content: "b";
}
.lnc-arrow-contract:before {
  content: "c";
}
.lnc-arrow-down:before {
  content: "d";
}
.lnc-arrow-down-up:before {
  content: "e";
}
.lnc-arrow-expand:before {
  content: "f";
}
.lnc-arrow-left:before {
  content: "g";
}
.lnc-arrow-left-right:before {
  content: "h";
}
.lnc-arrow-right:before {
  content: "i";
}
.lnc-arrow-right-left:before {
  content: "j";
}
.lnc-arrow-up:before {
  content: "k";
}
.lnc-arrow-up-down:before {
  content: "l";
}
.lnc-arrow-x:before {
  content: "m";
}
.lnc-arrow-y:before {
  content: "n";
}
.lnc-at:before {
  content: "o";
}
.lnc-badge:before {
  content: "p";
}
.lnc-battery-empty:before {
  content: "q";
}
.lnc-battery-full:before {
  content: "r";
}
.lnc-battery-halfempty:before {
  content: "s";
}
.lnc-battery-halffull:before {
  content: "t";
}
.lnc-bed:before {
  content: "u";
}
.lnc-bell:before {
  content: "v";
}
.lnc-bill:before {
  content: "w";
}
.lnc-book:before {
  content: "x";
}
.lnc-bookmark:before {
  content: "y";
}
.lnc-box:before {
  content: "z";
}
.lnc-box2:before {
  content: "A";
}
.lnc-briefcase:before {
  content: "B";
}
.lnc-brightness:before {
  content: "C";
}
.lnc-broom:before {
  content: "D";
}
.lnc-browser:before {
  content: "E";
}
.lnc-browser-software:before {
  content: "F";
}
.lnc-brush:before {
  content: "G";
}
.lnc-bubble:before {
  content: "H";
}
.lnc-bubble-check:before {
  content: "I";
}
.lnc-bubble-dots:before {
  content: "J";
}
.lnc-bubbles:before {
  content: "K";
}
.lnc-building:before {
  content: "L";
}
.lnc-bulb:before {
  content: "M";
}
.lnc-calculations:before {
  content: "N";
}
.lnc-calendar:before {
  content: "O";
}
.lnc-cctv:before {
  content: "P";
}
.lnc-chart:before {
  content: "Q";
}
.lnc-chart-down:before {
  content: "R";
}
.lnc-chart-pie:before {
  content: "S";
}
.lnc-chart-up:before {
  content: "T";
}
.lnc-check:before {
  content: "U";
}
.lnc-checkbox:before {
  content: "V";
}
.lnc-clock:before {
  content: "W";
}
.lnc-clone:before {
  content: "X";
}
.lnc-cloud:before {
  content: "Y";
}
.lnc-cloud-download:before {
  content: "Z";
}
.lnc-cloud-no:before {
  content: "0";
}
.lnc-cloud-upload:before {
  content: "1";
}
.lnc-code:before {
  content: "2";
}
.lnc-coffe:before {
  content: "3";
}
.lnc-cog:before {
  content: "4";
}
.lnc-comparation:before {
  content: "5";
}
.lnc-contract:before {
  content: "6";
}
.lnc-cpu:before {
  content: "7";
}
.lnc-creditcard:before {
  content: "8";
}
.lnc-cursor:before {
  content: "9";
}
.lnc-cut:before {
  content: "!";
}
.lnc-dashboard:before {
  content: "\"";
}
.lnc-database:before {
  content: "#";
}
.lnc-down:before {
  content: "$";
}
.lnc-download:before {
  content: "%";
}
.lnc-download2:before {
  content: "&";
}
.lnc-drop:before {
  content: "'";
}
.lnc-earth:before {
  content: "(";
}
.lnc-equal:before {
  content: ")";
}
.lnc-eraser:before {
  content: "*";
}
.lnc-exchange:before {
  content: "+";
}
.lnc-exchange2:before {
  content: ",";
}
.lnc-expand:before {
  content: "-";
}
.lnc-eyedropper:before {
  content: ".";
}
.lnc-file:before {
  content: "/";
}
.lnc-file-broken:before {
  content: ":";
}
.lnc-file-check:before {
  content: ";";
}
.lnc-file-download:before {
  content: "<";
}
.lnc-file-image:before {
  content: "=";
}
.lnc-file-minus:before {
  content: ">";
}
.lnc-file-plus:before {
  content: "?";
}
.lnc-file-text:before {
  content: "@";
}
.lnc-file-upload:before {
  content: "[";
}
.lnc-file-warning:before {
  content: "]";
}
.lnc-file-x:before {
  content: "^";
}
.lnc-filter:before {
  content: "_";
}
.lnc-fingerprint:before {
  content: "`";
}
.lnc-flag:before {
  content: "{";
}
.lnc-flashdrive:before {
  content: "|";
}
.lnc-folder:before {
  content: "}";
}
.lnc-fragile:before {
  content: "~";
}
.lnc-glass:before {
  content: "\\";
}
.lnc-grid:before {
  content: "\E000";
}
.lnc-grid-masonery:before {
  content: "\E001";
}
.lnc-harddrive:before {
  content: "\E002";
}
.lnc-headphones:before {
  content: "\E003";
}
.lnc-heart:before {
  content: "\E004";
}
.lnc-home:before {
  content: "\E005";
}
.lnc-image:before {
  content: "\E006";
}
.lnc-in:before {
  content: "\E007";
}
.lnc-inbox:before {
  content: "\E008";
}
.lnc-infinity:before {
  content: "\E009";
}
.lnc-info:before {
  content: "\E00A";
}
.lnc-injection:before {
  content: "\E00B";
}
.lnc-internet:before {
  content: "\E00C";
}
.lnc-internet2:before {
  content: "\E00D";
}
.lnc-intersect:before {
  content: "\E00E";
}
.lnc-key:before {
  content: "\E00F";
}
.lnc-laptop:before {
  content: "\E010";
}
.lnc-layers:before {
  content: "\E011";
}
.lnc-layout:before {
  content: "\E012";
}
.lnc-leaf:before {
  content: "\E013";
}
.lnc-left:before {
  content: "\E014";
}
.lnc-lifering:before {
  content: "\E015";
}
.lnc-link:before {
  content: "\E016";
}
.lnc-list:before {
  content: "\E017";
}
.lnc-list2:before {
  content: "\E018";
}
.lnc-location:before {
  content: "\E019";
}
.lnc-lock-off:before {
  content: "\E01A";
}
.lnc-lock-on:before {
  content: "\E01B";
}
.lnc-magnet:before {
  content: "\E01C";
}
.lnc-mail:before {
  content: "\E01D";
}
.lnc-map:before {
  content: "\E01E";
}
.lnc-megaphone:before {
  content: "\E01F";
}
.lnc-microphone:before {
  content: "\E020";
}
.lnc-microphone-no:before {
  content: "\E021";
}
.lnc-microscope:before {
  content: "\E022";
}
.lnc-minus:before {
  content: "\E023";
}
.lnc-mobile:before {
  content: "\E024";
}
.lnc-monitor:before {
  content: "\E025";
}
.lnc-move:before {
  content: "\E026";
}
.lnc-move2:before {
  content: "\E027";
}
.lnc-network:before {
  content: "\E028";
}
.lnc-onoff:before {
  content: "\E029";
}
.lnc-out:before {
  content: "\E02A";
}
.lnc-pause:before {
  content: "\E02B";
}
.lnc-pencil:before {
  content: "\E02C";
}
.lnc-pencil-ruler:before {
  content: "\E02D";
}
.lnc-peper-plane:before {
  content: "\E02E";
}
.lnc-peperclip:before {
  content: "\E02F";
}
.lnc-phone:before {
  content: "\E030";
}
.lnc-phone-ringing:before {
  content: "\E031";
}
.lnc-photo:before {
  content: "\E032";
}
.lnc-photo-no:before {
  content: "\E033";
}
.lnc-pin:before {
  content: "\E034";
}
.lnc-plaster:before {
  content: "\E035";
}
.lnc-play:before {
  content: "\E036";
}
.lnc-plus:before {
  content: "\E037";
}
.lnc-power-cable:before {
  content: "\E038";
}
.lnc-presentation:before {
  content: "\E039";
}
.lnc-printer:before {
  content: "\E03A";
}
.lnc-question:before {
  content: "\E03B";
}
.lnc-redo:before {
  content: "\E03C";
}
.lnc-refresh:before {
  content: "\E03D";
}
.lnc-reload:before {
  content: "\E03E";
}
.lnc-repeat:before {
  content: "\E03F";
}
.lnc-replace:before {
  content: "\E040";
}
.lnc-right:before {
  content: "\E041";
}
.lnc-rocket:before {
  content: "\E042";
}
.lnc-router:before {
  content: "\E043";
}
.lnc-ruler:before {
  content: "\E044";
}
.lnc-safety:before {
  content: "\E045";
}
.lnc-search:before {
  content: "\E047";
}
.lnc-settings:before {
  content: "\E048";
}
.lnc-settings2:before {
  content: "\E049";
}
.lnc-share:before {
  content: "\E04A";
}
.lnc-shield-check:before {
  content: "\E04B";
}
.lnc-shop-basket:before {
  content: "\E04C";
}
.lnc-shop-cart:before {
  content: "\E04D";
}
.lnc-shop-cart2:before {
  content: "\E04E";
}
.lnc-shuffle:before {
  content: "\E04F";
}
.lnc-slash:before {
  content: "\E050";
}
.lnc-spanner:before {
  content: "\E051";
}
.lnc-speaker:before {
  content: "\E052";
}
.lnc-speaker-minus:before {
  content: "\E053";
}
.lnc-speaker-plus:before {
  content: "\E054";
}
.lnc-speaker-x:before {
  content: "\E055";
}
.lnc-star:before {
  content: "\E056";
}
.lnc-stop:before {
  content: "\E057";
}
.lnc-tag:before {
  content: "\E059";
}
.lnc-target:before {
  content: "\E05A";
}
.lnc-trash:before {
  content: "\E05B";
}
.lnc-trolley:before {
  content: "\E05C";
}
.lnc-undo:before {
  content: "\E05D";
}
.lnc-up:before {
  content: "\E05E";
}
.lnc-upload:before {
  content: "\E05F";
}
.lnc-upload2:before {
  content: "\E060";
}
.lnc-user:before {
  content: "\E061";
}
.lnc-user2:before {
  content: "\E062";
}
.lnc-user3:before {
  content: "\E063";
}
.lnc-users:before {
  content: "\E064";
}
.lnc-video-player:before {
  content: "\E065";
}
.lnc-wallet:before {
  content: "\E066";
}
.lnc-warning:before {
  content: "\E067";
}
.lnc-wheelchair:before {
  content: "\E068";
}
.lnc-wifi:before {
  content: "\E069";
}
.lnc-x:before {
  content: "\E06A";
}
.lnc-zoom-in:before {
  content: "\E06B";
}
.lnc-zoom-out:before {
  content: "\E06C";
}
.lnc-firewall:before {
  content: "\E06D";
}
.lnc-down-double:before {
  content: "\E046";
}
.lnc-file-search:before {
  content: "\E06E";
}
.lnc-filter2:before {
  content: "\E06F";
}
.lnc-flash:before {
  content: "\E070";
}
.lnc-left-double:before {
  content: "\E071";
}
.lnc-right-double:before {
  content: "\E072";
}
.lnc-save:before {
  content: "\E073";
}
.lnc-save-plus:before {
  content: "\E074";
}
.lnc-server:before {
  content: "\E075";
}
.lnc-table:before {
  content: "\E076";
}
.lnc-table2:before {
  content: "\E077";
}
.lnc-time-back:before {
  content: "\E078";
}
.lnc-time-forward:before {
  content: "\E079";
}
.lnc-up-double:before {
  content: "\E07A";
}
.lnc-align-center:before {
  content: "\E058";
}
.lnc-align-left:before {
  content: "\E07B";
}
.lnc-align-right:before {
  content: "\E07C";
}
.lnc-car:before {
  content: "\E07D";
}
.lnc-cashregister:before {
  content: "\E07E";
}
.lnc-file-excel:before {
  content: "\E07F";
}
.lnc-file-pdf:before {
  content: "\E080";
}
.lnc-file-word:before {
  content: "\E081";
}
.lnc-puzzle:before {
  content: "\E082";
}
.lnc-rotate-vertical:before {
  content: "\E083";
}
.lnc-rotate-horizontal:before {
  content: "\E084";
}
.lnc-sum:before {
  content: "\E085";
}
.lnc-usb:before {
  content: "\E086";
}
.lnc-checklist:before {
  content: "\E087";
}
.lnc-checklist2:before {
  content: "\E088";
}
.lnc-concentrate:before {
  content: "\E089";
}
.lnc-cutlery:before {
  content: "\E08A";
}
.lnc-exchange3:before {
  content: "\E08B";
}
.lnc-graduate-cap:before {
  content: "\E08C";
}
.lnc-id:before {
  content: "\E08D";
}
.lnc-printer-check:before {
  content: "\E08E";
}
.lnc-slide-left:before {
  content: "\E08F";
}
.lnc-slide-right:before {
  content: "\E090";
}
.lnc-user-switch:before {
  content: "\E091";
}
.lnc-aggregate:before {
  content: "\E092";
}
.lnc-backspace:before {
  content: "\E093";
}
.lnc-code2:before {
  content: "\E094";
}
.lnc-edit:before {
  content: "\E095";
}
.lnc-handshake:before {
  content: "\E096";
}
.lnc-input:before {
  content: "\E097";
}
.lnc-intersect2:before {
  content: "\E098";
}
.lnc-logo-dropbox:before {
  content: "\E099";
}
.lnc-logo-facebook:before {
  content: "\E09A";
}
.lnc-logo-gdrive:before {
  content: "\E09B";
}
.lnc-logo-hangouts:before {
  content: "\E09C";
}
.lnc-logo-instagram:before {
  content: "\E09D";
}
.lnc-logo-linkedin:before {
  content: "\E09E";
}
.lnc-logo-messenger:before {
  content: "\E09F";
}
.lnc-logo-paypal:before {
  content: "\E0A0";
}
.lnc-logo-pinterest:before {
  content: "\E0A1";
}
.lnc-logo-playstore:before {
  content: "\E0A2";
}
.lnc-logo-skype:before {
  content: "\E0A3";
}
.lnc-logo-twitter:before {
  content: "\E0A4";
}
.lnc-logo-viber:before {
  content: "\E0A5";
}
.lnc-logo-whatsapp:before {
  content: "\E0A6";
}
.lnc-logo-youtube:before {
  content: "\E0A7";
}
.lnc-measure:before {
  content: "\E0A8";
}
.lnc-percent:before {
  content: "\E0A9";
}
.lnc-scan-barcode:before {
  content: "\E0AA";
}
.lnc-scan-number:before {
  content: "\E0AB";
}
.lnc-scan-qr:before {
  content: "\E0AC";
}
.lnc-user-speaker:before {
  content: "\E0AD";
}
.lnc-users-connected:before {
  content: "\E0AE";
}
