.bill-activ-title{
    color: #153a78;
}
.bill-activ-content{
    text-align: justify;
}
.bill-page-title{
    display: block;
    font-weight: bold !important;
    color: #153a78  !important;
}

.bill-spiner {
    margin: auto;
    margin-top: calc(100vh - 65vh);
    width: 100px !important;
    height: 100px !important;
}

.bill-table {
    margin-top: 5px;
    border-collapse: collapse;
    color: #153a78 !important;
    background-color: white;
}

.bill-thead {
    position: relative !important;
    background-color: white;
    border-bottom: 2px solid #153a78;
    text-transform: uppercase;
}

.bill-td, .bill-th {
    text-align: center !important;
    color: #222222;
}
.bill-th{
    font-size: 18px;
    font-weight: bold;
    color: #153a78;
    
}

.bills-value {
    font-weight: bold;
    color: #222222;
    text-align: right !important;
}
.bill-text{
    text-align: left !important;
}
.bill-box {
    width: 100%;
    box-shadow: 5px 0px 10px 3px lightgrey;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    padding: 10px;
}

.bill-td-box-left {
    color: #153a78;
    font-weight: bold;
    width: 25%;
}

.bill-td-box-right {
    color: black;
    float: right;
}
.bill-box-view{
    width: 100%;
}
.bill-box-value{
    font-weight: bold;
}
.bill-table-view {
    width: 100%;
    display: block;
    padding: 10px 0px;
    box-shadow: 5px 0px 10px 3px lightgrey;
    border-radius: 20px;
    margin-top: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.bill-box-view {
    margin-top: 15px;
    display: none;
}

@media screen and (max-width: 600px) {
    .bill-td-box-right {
        font-size: 2.5vw;
    }
    .bill-table-view {
        display: none;
    }
    .bill-box-view {
        display: block;
    }
    .bill-td-box-left {
        font-size: 2.5vw;
    }
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white !important;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5faff;
}