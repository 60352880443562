.home-title{
    color:  #2674b3;
    font-size: 1.25rem;
}
.home-td-left{
    color:  #2674b3;
    font-weight: 600;
    font-size: 1rem;
}
.no-pad-mar{
    padding: 0px !important;
    margin: 0px !important;
}
.master{
   display: inline;
}
.home-content{
    margin: 20px;
    width: 95% !important;
}
.home-card-box{
    padding: 5px;
    height: 100%;
    border: none !important;
    box-shadow: 6px 6px 5px 0px rgba(38,116,179,0.46) !important;
}
.home-info-box{
    background-color: red;
    color: white;
    height: 90px;
    border-radius: 20px;
    margin: 3px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    padding: 5px;
}
.home-info-icon-content{
    position: relative;
    transform: translateY(30%);
    margin: auto;
}

.home-info-box-icon{
    font-size: 40px ;
    margin-left: 20px;
}

.home-font {
    font-size: 2.2rem!important;
    font-weight: bold;
}
.home-word-wrap{
    word-wrap: break-word;
}

#home-box-positiv{
    background-image: linear-gradient(175deg, rgba(21,58,120,1)0%, rgba(21,58,120,1) 40%, rgba(39,93,185,1) 100%);
}
#home-box-negativ{
    background-image: linear-gradient(175deg, rgba(255,76,76,1)0%, rgba(255,76,76,1) 40%, rgba(255,126,51,1) 100%);
}
#home-box2{
    background-image: linear-gradient(175deg, rgba(153,153,153,1)0%, rgba(153,153,153,1) 40%, rgba(204,204,204,1) 100%);
}
#home-box3{
    background-image: linear-gradient(175deg, rgba(153,204,51,1)0%, rgba(153,204,51,1) 40%, rgba(192,196,70,1) 100%);
}
#home-box4{
   background-image: linear-gradient(175deg, rgba(60,161,220,1)0%, rgba(60,161,220,1) 40%, rgba(60,196,220,1) 100%);
}
.home-info-card-icon{
    color: #153a78;
    font-size: 24px !important;
 }
 .home-info-card-label{
     color: #153a78;
     font-size: 20px;
     font-weight: bold; 
 }
 .home-info-card-h5{
     color: black;
 }
 .home-info-card-h6{
     color: gray;
 }

@media (min-width: 200px) {  
  .home-h4 {font-size: 1.1rem !important}
  .home-info-box-icon{font-size: 2rem !important;}
}

@media (min-width: 768px) {  
  .home-h4 {font-size:1.1rem !important;}
  .home-info-box-icon{font-size: 2rem !important;}
}
@media (max-width: 900px) {  
    .home-content{
         padding-right: 5px !important;  
         padding-left: 5px !important;}
  }

@media (min-width: 992px) { 
  .home-h4 {font-size: 1.0rem!important;} 
  .home-info-box-icon{font-size: 2rem !important;}
}


@media (min-width: 1200px) {  
  .home-h4 {font-size:1.2rem !important;}    
  .home-info-box-icon{font-size: 2rem !important;}
}
.home-info-box-label{
    margin: 20px 0 0 15px;
    font-size: 12px !important;
}
.home-info-box-value{
    margin-left: 15px;
    font-size: 30px ;
    font-family: Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

@media (min-width: 500px){
    .home-info-box-label{
        font-size: 10px !important;
    }
    .home-info-box-value{
        font-size: 20px !important;
    }
}
@media (min-width: 768px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
        font-size: 22px !important;
    }
}
@media (min-width: 830px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
        font-size: 24px !important;
    }
}
@media (min-width: 1200px){
    .home-info-box-label{
        font-size: 11px !important;
    }
    .home-info-box-value{
        font-size: 22px !important;
    }
}
@media (min-width: 1320px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
            font-size: 23px !important;
        }
    }
@media (min-width: 1558px){
    .home-info-box-label{
        font-size: 12px !important;
    }
    .home-info-box-value{
            font-size: 30px !important;
        }
    }

