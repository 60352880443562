.settings-tab-content{
    height: auto !important;
}
.settings-tab-container{
    width: 100% !important ;
}
.settings-profil-picture-content{
    width: 250px;
    padding: 5px;
    margin-top: 2px;
    height: 250px !important;
    height: 100%;
    margin: auto;
}
.settings-border-right{
    border-right: 1px solid gray;
}

.settings-nav-link {
    padding: 6px !important;
    color: gray !important;
}

/* The switch - the box around the slider */
.settings-switch {
  position: relative;
  float: right;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.settings-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.settings-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.settings-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .settings-slider {
  background-color: #2196F3;
}

input:focus + .settings-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .settings-slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.settings-slider.settings-round {
  border-radius: 17px;
}

.settings-slider.settings-round:before {
  border-radius: 50%;
}

.settings-input-label{
    color: gray;
    font-size: 15px;
}
.settings-title-label{
  color: #153a78;
  font-weight: bold;
  font-size: 18px;
}
.image-container {
  position: relative;
  padding: 0 !important;
 
}

.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 12px; 
}
.btn-upload{
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: #555 !important;
  color: white !important;
  opacity: 0.8;
  font-size: 20px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 12px; 
}
.icon-upload{
  font-size: 20px;
}



.disabled{
  pointer-events: none;
  opacity: 0.7;
}

.custom-button-modal{
  float: right;
}
.modal-title{
  font-size: 18px !important;
  color: #153a78;
  font-weight: bold !important;
}
.settings-input-field{
  height: 25px !important;
}
.settings-modal-input-label{
   font-size: 14px !important;
}
.settings-modal-button{
  text-align: center;
}
.modal-backdrop{
  background-color: #153a78 !important;
  
}
.note-label{
  color: red;
  text-align: justify;
  margin: 3px;
 
}
.settings-custom-button{
  margin-left: 5px;
}
.settings-input-combobox{
  float: right;
  color: #153a78 !important;
  font-weight: bold !important;
  text-transform: uppercase;
  width: 150px !important;
}

@media screen and (max-width: 500px) {
  .settings-profil-picture{
      width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .settings-profil-picture{
      width: 350px;
  }
  .settings-input-combobox{
    float: left;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  .settings-button{
    margin-top: 25px;
    position: absolute;
  }
  
}