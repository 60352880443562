.main-no-padding {
    padding: 0 !important;
}

.main-no-margin {
    margin: 0 !important;
}

.main-side-and-content {
    position: relative;
    height: calc(100vh - 56px);
    width: 100%;
}

.main-profil-pic {
    width: 60%;
    padding-top: 10px;
}

.main-side-link-activ:hover {
    color: white;
    text-decoration: none;
}


/*End Side Menu Style*/


/* Main Content Style*/

.main-main-content {
    background-color: white !important;
    z-index: 50;
    padding: 10px;
    margin-left: 60px !important;
    transition: margin-left 0.3s ease-out;
    height: 100%;
}

.logo-picture {
    padding: 10px 25px;
    background-color: white;
}

.logo-picture-mini {
    padding: 10px 3px 0 5px;
    background-color: white;
}

.profil-picture {
    border-radius: 10px;
}

.side-menu {
    background-color: #153a78!important;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 260px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    z-index: 100;
}

.side-menu.main-open {
    transform: translateX(0);
}

.side-content {
    overflow-y: auto;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.side-menu-item {
    padding: 3px 5px;
    color: #153a78;
    cursor:  pointer !important;
}

.side-menu-item:hover .side-menu-item-label {
    color: rgb(166, 236, 253) !important;
}

.side-menu-item:hover  {
    color: #3ca1dc!important;
    text-decoration: none;
}
.side-menu-item-icon{
    position: relative;
    top: 3px;
}

.side-menu-item-inverse {
    padding: 3px 5px;
    color: white;
    position: relative;
}

.side-menu-item-inverse:hover .side-menu-item-label {
    color: rgb(166, 236, 253) !important;
}



.side-menu-item-activ {
    color: #3ca1dc!important;
    padding: 3px 5px;
}

.main-profil-pic {
    width: 60%;
    padding-top: 10px;
}

.side-menu-item-icon {
    font-size: 20px !important;
    margin-top: 5px;
}

.side-menu-item-label {
    font-size: 16px;
    margin-left: 8px;
    font-weight: bold;
    cursor: pointer;
}

.side-bottom {
    background-color: white;
    height: 100%;
}

#user-info {
    text-align: center;
}

#first-half {
    background-color: white;
    border-bottom-left-radius: 25px;
}

#second-half {
    height: auto;
    background-color: white;
    color: white;
}

#second-half-child {
    border-radius: 0 20px 20px 0;
    background: rgb(21,58,120);
    background: linear-gradient(165deg, rgba(21,58,120,1) 0%, rgba(60,161,220,1) 80%, rgba(60,161,220,1) 100%);
}

#image-container {
    padding: 20px;
    margin: auto;
    max-width: 200px;
    max-height: 200px;
}

.img-profil {
    border-radius: 20px;
}

#div-1 {
    height: 25px;
    background-color: white;
}

#div-2 {
    background-color: #3ca1dc;
}

#div-3 {
    height: 25px;
    background-color: white;
    border-top-left-radius: 25px;
}

.hr {
  
    background-color: #2c76b2;
    width: 92%;
    margin: auto;
}
.side-menu-1 {
    background-color: #153a78;
    ;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    max-width: 60px;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
    z-index: 100;
    overflow-y: auto;
}

.side-menu-1.main-open-1 {
    transform: translateX(-100%);
}

@media(min-width: 769px) {
    .side-menu {
        transform: translateX(0);
    }
    .side-menu-1 {
        transform: translateX(-100%);
    }
    .main-main-content {
        background-color: lightgray;
        margin-left: 270px !important;
        padding: 30px;
    }
   
}
@media(max-width: 769px) {
    .side-menu-item{
        padding: 3px 10px;
    }
    .side-menu-item-inverse {
        padding: 3px 10px !important;
    }

}
/* Custom ScrollBar */

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  /* End Custom ScrollBar */

  .notification-badge{
    padding: 3px !important;
    position: absolute;
    top: -7px;
    z-index: 5;
    left: 16px;
}
 .notification-badge-child{
    position: absolute;
    bottom: 122px;
    left: 32px;
    width: 15px;
    height: 15px;  
 }
 
 