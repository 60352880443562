
@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  margin: 0;
  padding: 0;  
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat' !important;
}
.container-fluid{
  padding: 0 !important;
}


