.card-header-colors{
    color:white!important;
    background-image: linear-gradient(to left, #153a78, #1b5292, #236cab, #2e86c4, #3ca1dc) !important;
}

.notDisplay{
    display: none;
}

.titlePrijavaStanja, .text{
    font-weight: bold !important;
    color: #153a78 !important;
}

.text{
    font-size: 15px;
}

.whole-box{
    border: 0px !important;
    height: 100%;    
}

.header-box{
    background-color: white !important;
    border: none !important;
    font-size: 18px !important;
    border-bottom: 2px solid #153a78 !important;
    border-radius: 10px 10px 0px 0px !important;
    box-shadow: 5px 5px 8px  lightgray, -5px 0 5px -5px lightgray, 0 -5px 15px -5px lightgray;
}

.body-box{
    background-color: #f5faff;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0px 0px 10px 10px !important;
    box-shadow: 5px 5px 8px lightgray, -5px 0 5px -5px lightgray;
}

.footer-box{
    background-color: white !important;
    border: none !important;
}

.card-box{
    border: none !important;
    overflow: hidden;
}

.fieldBox{
    background-color: #f5faff !important;
    border: none !important;
    font-weight: bold !important;
}

.btnPrijaviStanje{
    background-color: #153a78 !important; 
    color: white;
    border: none;
    padding: 4px 8px !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px !important;
    border-radius: 10px !important;
    text-transform: uppercase;
}

.btnPrijaviStanje:hover{
    opacity: 0.8;
    transition: opacity 0.3s;
}



.ps-table {
    margin-top: 5px;
    border-collapse: collapse;
    color: #153a78 !important;
    background-color: white;
}

.ps-table-body{
    background-color: #f5faff;
}

.ps-table-view {
    width: 100%;
    display: block;
    padding: 10px 0px ;
    box-shadow: 5px 0px 10px 3px lightgrey;
    /* border-radius: 20px; */
    margin-top: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.ps-thead {
    position: relative !important;
    border-bottom: 2px solid #153a78;
    text-transform: uppercase;
}