.reset-container{
               /* background-color: rgba(211, 211, 211, 0.5);*/
                padding: 10px;
                width: 100%;
                height: 100%;
                margin: auto;
           
               
           }
.cover{
               height: 100%;
               min-height: 100vh;
               position: relative;
           }

.text1 {
       font-size:24px;
       color:white;
       padding: 5px;
}

.text {
    font-size:14px;
    color:white;
    padding: 3px;
}
.txt {
    padding: 7px;
}
