
.notDisplay{
    display:none;
}

.poslOcitanje{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: .25rem;
    background-color: white;
}

.poslOcitanjeField{
    width: 20% !important;    
    background-color: white !important;
    border: none !important;
    font-weight: bold !important;
}

.utrosakField{
    width: 40% !important;
}

.kalkPotrosnje{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: .25rem;
    background-color: white;
}

.kalkPotrosnjeFont{
    font-size: 17px;
}

.poljaUtroska{
    background-color: #d4d7da !important;
    max-width: 200px;
}

.poljaUtroskaModal{
    background-color: #ffffff !important;
    max-width: 200px;
    margin-right: 50px !important;
    border: 0px !important;
}

.labelModal{
    margin-left: 20px !important;
}

.modalTableLeft{
    color: gray;
    width: 100%;
    padding-left: 20px !important;
}

.modalTableRight{
    color:gray;
    font-weight: bold;
    padding-right: 20px !important;
    text-align: right;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white !important;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5faff;
}

.titleKalkulatorPotrosnje, .text{
    font-weight: bold !important;
    color: #153a78 !important;
}

.text{
    font-size: 15px;
}

.whole-box{
    border: 0px !important;
    height: 100%;
}

.header-box{
    background-color: white !important;
    border: none !important;
    font-size: 18px !important;
    border-bottom: 2px solid #153a78 !important;
    border-radius: 10px 10px 0px 0px !important;
    box-shadow: 5px 5px 8px  lightgray, -5px 0 5px -5px lightgray, 0 -5px 15px -5px lightgray;
}

.body-box{
    background-color: #f5faff;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 5px 10px 8px lightgray, -5px 0 5px -5px lightgray;
}

.modalBody{
    padding: 0px !important;
}
