.custom-button{
    float: right;
}
@media screen and (max-width: 967px) {
    .custom-button{
        float: left;
    }
}
.red{
    color: red;
    font-weight: bold;
}
.green{
    color: green;
    font-weight: bold;
}
.inline{
    display: inline;
    padding-left: 7px;
}

.measuring-point-box {
    width: 100%;
    box-shadow: 5px 0px 10px 3px lightgrey;
    margin-bottom: 8px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.header-icon{
    margin-right: 10px;
}
.backslash{
    margin-left: 10px;
    color: gray;
    font-weight: bold;
}
.data-picker{
    background-color: #f5faff;
}
.react-datepicker-wrapper{
    width: 100%;
}
.data-picker{
    width: 100%;
}
.table-box{
    border-bottom: 1px solid #153a78;
    border-top: 1px solid #153a78;
}
.mp-bold-value{
    font-weight: bold;
}
.text-font-size{
    font-size: 18px;
}
.mp-table-border-top{
    border-top: 1px solid #153a78;
    margin-top: 3px;
}
@media screen and (min-width: 800px) {
    .text-font-size {
        font-size: 18px !important;
    }
}
@media screen and (max-width: 800px) {
    .text-font-size {
        font-size: 2vw;
    }
}
@media screen and (max-width: 650px) {
    .text-font-size {
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 500px) {
    .text-font-size {
        font-size: 3vw;
    }
}
@media screen and (max-width: 400px) {
    .text-font-size {
        font-size: 4vw;
    }
}
