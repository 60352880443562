.payment-spiner {
    margin: auto;
    margin-top: calc(100vh - 65vh);
    width: 100px !important;
    height: 100px !important;
}
.payment-page-title{
    display: block;
    font-weight: bold !important;
    color: #153a78  !important;
}
.payment-table {
    margin-top: 5px;
    border-collapse: collapse;
    color: #153a78 !important;
    background-color: white;
    
    
}

.payment-thead {
    position: relative !important;
    background-color: white;
    border-bottom: 2px solid #153a78;
    color: red !important;
    text-transform: uppercase;
}

.payment-td, .payment-th {
    text-align: center !important;
    color: #222222;
}
.payment-th{
    font-size: 18px;
    font-weight: bold;
    color: #153a78;
    
}

.payments-value {
    font-weight: bold;
    color: #222222;
    text-align: right !important;
}
.payment-text{
    text-align: left !important;
}

.payment-box {
    width: 100%;
    box-shadow: 5px 0px 10px 3px lightgrey;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.payment-td-box-left {
    color: #153a78;
    font-weight: bold;
    width: 25%;
}

.payment-td-box-right {
    color: black;
    float: right;
}

.payment-table-view {
    width: 100%;
    padding: 10px 0px;
    box-shadow: 5px 0px 10px 3px lightgrey;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.payment-box-view {
    margin-top: 15px;
    display: none;
}

.payment-box-value{
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .payment-td-box-right {
        font-size: 2.5vw;
    }
    .payment-table-view {
        display: none !important;
    }
    .payment-box-view {
        display: block;
        width: 100%;
    }
    .payment-td-box-left {
        font-size: 2.5vw;
    }
}
@media screen and (min-width: 800px) {
    .text-font-size {
        font-size: 2vw;
    }
}
@media screen and (max-width: 650px) {
    .text-font-size {
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 500px) {
    .text-font-size {
        font-size: 3vw;
    }
}
@media screen and (max-width: 400px) {
    .text-font-size {
        font-size: 4vw;
    }
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white !important;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5faff;
}