.wrapper {
    background-image: url('..\\images\\elektrokrajina_bck.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    min-height: 100vh;
}

.container {
    min-height: 100vh;
}

.ponuda-img {
    margin: auto;    
    font-size: 50px !important;
}

.labelForgotPassword {
    color: blue;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
    text-align: end;
}

.labelForgotPassword:hover{
    color:#052f72;
}

.ponuda-p {
    font-size: 12px;
    margin-top: 10px;
}

.form-container {
    background-color: white;
    height: 100%;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
}

.submit-button {
    background-color: #153a78!important;
    color: white!important;
    font-weight: bold;    
    border-radius: 100px !important;
}

.submit-button:hover{
    background-color: #1a73a7 !important;
}

h3>a {
    font-style: italic;
    text-decoration: underline;
    color: #2764c6;
    font-size: 20px;
    margin-top: 7px;
}

h3>a:hover {
    color: #052f72;
}

@media screen and (max-width: 600px) {
    .cover {
        padding: 0;
    }
    .logoImg {
        height: 50px;
        margin: 15px 10px 15px 10px;
    }
}

.horizontalLine {
    margin: 0;
    background-color: lightgray;
    height: 1px;
}

.nopadding{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px;
    margin-left: 0px !important;
}

.content{
    display: flex;
    flex-direction: column;
}

.content_order1{
    position: absolute;
    height: auto;
}

.content_order2{
    height: 100%;
    overflow-y: scroll;
}

.fieldsLine{    
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #153a78 !important;
    border-radius: 0% !important;
}

.comboBorder{
    border: 1px solid #153a78 !important;
}

.logo {
    padding: 10px 15px;
    background-color: white;
}

.buttonLogin{    
    min-width: 200px;
    max-width: 300px;
}
