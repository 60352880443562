.title{
    font-weight: bold !important;
    color: #153a78 !important;
}
.notification-container{
    text-align: right;
}
.notification-item{
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
   -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}
.item-header{
    font-size:14px;  
    color:  #153a78; 
}
.notification-header{
    border-bottom:  2px solid #153a78 !important;
}
.item-content{
    text-align: justify;
    font-size:14px;
    background-color: #f5faff !important;
    width: 100%;    
    padding: 15px;
}

.item-flag {
    background-color: #f5faff !important;
    width: 100%;  
    height: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
   -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
    
}
.item-content-dialog{
    text-align: justify;
    font-size:14px;
    background-color: #f5faff !important;
    width: 100%;
    height: 800px;   
    padding: 15px; 
}
.item-date{  
  color: darkgray;  
  padding-left: 15px;
  font-size: 10px;
}
.item-date-card{  
    color: darkgray;  
    font-size: 10px;
    width: 100px;
  }
.first-row{
    padding-bottom: 5px;
}
.icon2{
    margin-top: 7px;
    margin-right: 15px;
    margin-bottom: 5px;
    float: right;  
    color:  #153a78;          
}
.pag-color{  
   color: #153a78 !important;
   font-weight: bold;
}
.lnc-icon{
    font-size: 20px !important;
    color: #153a78;
}
.div-button{
    float:right;
}
.one-notification{
    height: 90%;  
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
   -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
   -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
 }
.item-icon {
    color: darkgray;  
    margin-top:50%;
}
.header-row{
    margin-left: 40%;  
    margin-top: 10px;
}
.date-row{
    left: 60%;
    color: darkgray;  
    padding-top:3%;
}
.last-row{
    bottom:10%;
    position: fixed;
    width: 100%;
}
.page-numbers{
    margin-left: 20%;
    margin-bottom: 10%;
    font-size: 14px;
    position: fixed   
}
.paging-row{
    bottom:10%;
    position: fixed;
}
@media screen and (max-width: 600px) {
    .page-numbers{
        left: -50px;
        margin-bottom: 10%;
        position: fixed;
        font-size: 8px;
        color: #153a78; 
    }
    .notification-item{
        width: 280px;
        height: auto;
        margin-left: 0px;
        margin-right: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3); 
    }   
    .item-content-dialog{
        text-align: justify;
        font-size:14px;
        background-color: #f5faff !important;
        width: 100%;
        height: auto;   
        padding: 15px; 
    }
}
