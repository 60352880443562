.custom-button{
  border: none;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-left: 5px;
  border-radius: 10px;
  text-transform: uppercase;
}
.custom-button-normal{
    background-color: #153a78; 
    color: white;
}
.custom-button-dark{
    background-color: transparent;
    color:  #153a78; 
}
.custom-button:hover{
    opacity: 0.8;
    transition: opacity 0.3s;
}
.custom-button-icon{
    padding-right: 8px;
    font-size: 13px !important;
}
.custom-button-disabled{
    opacity: .65 !important;
}

@media screen and (max-width: 600px) {
    .custom-button{
        padding-top: 3px;
        font-size: 12px;
    }
}